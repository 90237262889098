import {
    Card,
    CardHeader,
    Row,
    CardBody,
} from "reactstrap";
import React from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/yathra-logo.png';
import QRCode from 'react-qr-code';
import BlankImage from '../../assets/img/brand/blank_profile_pic.png';

const StudentIdCard = ({ student }) => {
    return (
        <div className="studentIdSec">
           <Card
            className="qRCard"
            style={{
                position: 'relative',
                margin: 'auto',
                color: 'white',
                borderRadius: '20px',
                textAlign: 'center',
                padding: '20px',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '130px',
                    // backgroundColor: '#2F4FA2',
                    zIndex: 0,
                }}
                className="shape"
            ></div>

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '10px',
                    backgroundColor: '#021341',
                    zIndex: 0,
                }}
            ></div>
            <CardHeader
                className="idCardHeader"
                style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
            >
                <img src={Logo} alt="Institute Logo" width={40} crossOrigin="anonymous" />
            </CardHeader>
            <div className={styles.qrBody} style={{ zIndex: 1, position: 'relative', height: '200px' }}>
                <div style={styles.studentInfo}>
                    <img src={
                        student.image_url
                            ? student.image_url
                            : BlankImage
                    } style={styles.photoPlaceholder} crossOrigin="Access-Control-Allow-Origin" />                    
                    <h6 style={{ marginBottom: '2px', marginTop: '8px', color: '#021341' }}>{student.registration_number}</h6>
                    <h5 style={{ marginBottom: '0px', color: '#021341' }}>{student.full_name}</h5>
                </div>
            </div>
            <Barcode
                className="barCodeId"
                value={student.barcode}
                height={40}
                fontSize={12}
                style={{ position: 'relative', zIndex: 1 }}
            />
        </Card>
            <Card
                className="qRCardBack"
                style={{
                    position: 'relative',
                    margin: 'auto',
                    color: 'white',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '20px',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '120px',
                        zIndex: 0,
                    }}
                    className="shape"
                ></div>
                <CardHeader
                    className="idCardHeader"
                    style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
                >
                    {/* <img src={Logo} alt="Institute Logo" width={100} crossOrigin="anonymous" /> */}
                </CardHeader>
                <CardBody className="qrBackBody" style={{ zIndex: 1, position: 'relative' }}>
                    <div className="qrDiv">
                        <QRCode value={student.barcode} size={90} className="code" />
                    </div>
                    <p className="termsTitle">Terms & Conditions</p>
                    <p className="terms">This card is non-transferable and the property of Yathra Institute, Kirinda.
                        It is the cardholder's responsibility to protect and maintain the condition of his/her card.
                    </p>
                </CardBody>
                <div className="instDetails">
                    <p style={{ marginBottom: '5px', fontSize: '8px' }}>If found please return to:</p>
                    <div>
                        <p>Yathra Institute</p>
                        <p>Kirinda</p>
                        <p>(071) 174 5037</p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

const styles = {
    header: {
      textAlign: 'center',
      marginBottom: '5px',
    },
    logo: {
      width: '40px',
      height: '50px',
    },
    instituteName: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    instituteDetails: {
      fontSize: '12px',
      color: '#555',
    },
    studentInfo: {
      textAlign: 'center',
    },
    photoPlaceholder: {
      width: '86px',
      height: '102px',
      borderRadius: '15px',
      backgroundColor: '#d9d9d9',
      margin: '0 auto',
    },
    barcode: {
      display: 'block',
      margin: '10px auto',
    },
    contactInfoSec: {
      textAlign: 'center',
      fontSize: '10px',
      color: '#333',
    },
    contactInfo: {
      textAlign: 'center',
      fontSize: '8px',
      color: '#333',
      lineHeight: '1.4'
    },
    qrBody: {
      padding:'0px',
      height: '200px'
    }
  };

export default StudentIdCard;
